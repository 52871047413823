// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.reports-body {
    padding: 0.25em;
}

div.reports-body div.reports-filter-combo {
    width: 100%;
}

div.reports-body button.reports-download-button {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./qwc2/plugins/style/Reports.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":["div.reports-body {\n    padding: 0.25em;\n}\n\ndiv.reports-body div.reports-filter-combo {\n    width: 100%;\n}\n\ndiv.reports-body button.reports-download-button {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
